import styled from "styled-components"
import { darken } from "polished"
import media from "styled-media-query"
import { colors } from "../../styles/colors"

export const HeaderWrapper = styled.header`
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;

  background-color: ${props =>
    props.visible
      ? `${colors.header}`
      : `${colors.headerTransparent}`
  };

  box-shadow: ${props =>
    props.visible
      ? `0px 1px 50px 0.5px rgba(0, 0, 0, 0.5);`
      : `none`
    };

  transition: box-shadow 0.3s;
  
  z-index: 5;

  ${media.lessThan("medium")`
    display: none;
  `}
`

export const MenuPhone = styled.div`
  ${media.greaterThan("769px")`
    display: none;
  `}
`

export const HeaderContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;

  height: ${props =>
    props.visible
      ? `3.5rem;`
      : `4.5rem;`
  };

  transition: height 0.3s;

  > a {
    z-index: 6;
  }
`

export const HeaderLogo = styled.img`
  padding: 0.9rem 0.9rem 0.9rem 0;
  margin-left: 5rem;
  height: 3.5rem;

  display: ${props =>
    props.visible
      ? `flex;`
      : `none;`
    };
  
  transform: scale(1);

  &:hover {
    transition: 0.2s linear;
    transform: scale(1.05);
  }
`

export const HeaderNavBar = styled.nav`
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 30px;

  color: ${colors.headerFont};
  font-size: ${props =>
    props.visible
      ? `0.94rem;`
      : `1rem;`
    };

  background-color: ${props =>
    props.visible
      ? `${colors.header}`
      : `${colors.headerTransparent}`
    };

    transition: background-color 0.3s;
`

export const HeaderList = styled.ul`
  position: absolute;
  display: flex;
  justify-content: flex-end;

  flex-direction: ${props => props.sub && "column"};

  border-radius: 5px;

  background: ${props => props.sub && `${colors.header}`};

  box-shadow: ${props => props.sub && "1px 2px 4px rgba(0,0,0,0.2)"};
  margin-top: ${props => props.sub && "14.8rem"};
  opacity: ${props => props.sub && !props.menuDown && 0};
  visibility: ${props => props.sub && !props.menuDown && "hidden"};
  transition: visibility 0.3s, opacity 0.3s linear;

  z-index: 5;
`

export const HeaderItem = styled.li`
  display: flex;
  
  align-items: ${props => !props.sub && "center"};
  white-space: ${props => !props.sub && "nowrap"};
  border-radius: 5px;

  > a {
    display: flex;
    color: ${props =>
      props.visible
        ? `${colors.headerFont}`
        : `${colors.headerFontTransparent}`
      };
  
    transition: color 0.3s;
    font-weight: bold;
    
    align-items: center;
    padding: 1.25rem 0.94rem;
    width: 100%;

    > svg {
      margin-left: 1rem;
      transition: 0.3s linear;
      transform: ${props =>
        props.menuDown
          ? `rotate(180deg) scale(1.2);`
          : `rotate(0deg) scale(1);`
        };
    }

    transition: 0.2s linear;
    transform: scale(1);
    &:hover {
      transition: transform 0.2s linear;
      transform: scale(1.01);
    }
  }

  &:hover {
    transition: 0.2s linear;
    background: ${props =>
      props.visible
        ? `${darken(0.05, `${colors.headerSelected}`)}`
        : `${darken(0.1, `${colors.headerTransparentSelected}`)};`
      };
    transform: scale(1.01);
  }
`

export const MenuOptionItem = styled.span`
  display: flex;
  
  align-items: ${props => !props.sub && "center"};
  white-space: ${props => !props.sub && "nowrap"};
`
