export const colors = {
  main: "#ffa726",
  second: "#594941",
  containerBackground: '#b4a197',
  checkColor: '#8cc63f',
  mainFont: "#FFF",
  secondFont: "#818285",
  inputFont: "#686868",
  content: "#fff",


  header: "#ffa726",
  headerSelected: "#eeaa00",
  headerFont: "#FFF", // "#010864"
  // headerFontSelected: "rgba(1, 8, 100, 0.8)", // "#010864"
  headerTransparent: "rgba(5, 5, 5, 0)",
  headerTransparentSelected: "rgba(5, 5, 5, 0.3)",
  headerFontTransparent: "#FFF",
  // headerFontTransparentSelected: "rgba(1, 8, 100, 0.8)", // "#010864"
}
