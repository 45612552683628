import React from "react"

import { mainFooterData } from "./content"
import { links } from "./links"
import Icons from "./Icons"

import {
  FooterWrapper,
  FooterTitle,
  FooterContent,
  SecondFooter,
  FooterLinksList,
  FooterLinkItem,
  IconWrapper,
  SocialMediaText,
} from "./styles"

export default function MainFooter() {
  const { address, title } = mainFooterData

  return (
    <FooterWrapper>
      <FooterContent>
        <FooterLinksList>
          {links.map((itemLink, idx) => {
            const Icon = Icons[itemLink.label]

            return (
              <FooterLinkItem key={idx} href={itemLink.url} target="_blank">
                <IconWrapper>
                  <Icon color={itemLink.color} size="34" />
                  <SocialMediaText>{itemLink.label}</SocialMediaText>
                </IconWrapper>
              </FooterLinkItem>
            )
          })}
        </FooterLinksList>
        <SecondFooter>{address}</SecondFooter>
      </FooterContent>
    </FooterWrapper>
  )
}
