import React from "react"
import PropTypes from "prop-types"

import { ToastContainer } from "react-toastify"
import Header from "../Header"
import Footer from "../MainFooter"

import GlobalStyles from "../../styles/global"
import { LayoutWrapper, LayoutMain } from "./styles"

export default function Layout({ children }) {
  return (
    <LayoutWrapper>
      <ToastContainer autoClose={1500} />
      <GlobalStyles />
      <Header />
      <LayoutMain>{children}</LayoutMain>
      <Footer />
    </LayoutWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
