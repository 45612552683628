export const menu = [
  {
    label: "Home",
    url: "/",
    subUrl: [],
  },
  {
    label: "Segmentos",
    url: "",
    subUrl: [
      {
        label: "Profissionais Liberais",
        url: "/liberal",
        subUrl: [],
      },
      {
        label: "Empresas de Prestação de Serviços",
        url: "/servicos",
        subUrl: [],
      },
      {
        label: "Saúde e Bem Estar",
        url: "/saude",
        subUrl: [],
      },
    ],
  },
  {
    label: "Abertura de Empresa",
    url: "/abertura-de-empresa",
    subUrl: [],
  },
  {
    label: "Contato",
    url: "/contato",
    subUrl: [],
  },
]
