import styled from "styled-components"
import { darken } from "polished"
import { colors } from "../../styles/colors"

export const MenuWrapper = styled.div`
  background: ${colors.main};
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 3;
`

export const LogoContainer = styled.div`
  padding: 12px 5px 12px 35px;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const ImageLogo = styled.img`
  height: 3rem;
`

export const ButtonBurger = styled.button`
  position: absolute;
  left: 1rem;
  top: 1rem;
  border: 0;
  background: transparent;
  padding: 8px 15px;
  cursor: pointer;
  outline: none;

  ${props =>
    props.menuDown &&
    `
  > span:first-child {
      -webkit-transform-origin: 2px 9px;
      transform-origin: 2px 9px;

      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }

    > span:last-child {
      -webkit-transform-origin: 2px -5px;
      transform-origin: 2px -5px;

      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }

    & + ul {
      max-height: 500px;
    }
  `}
`
export const Dot = styled.span`
  display: block;
  width: 4px;
  height: 4px;
  background: #fff;
  margin: 3px 0;
  border-radius: 4px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
`

export const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;

  > a {
    color: ${colors.mainFont};
  }
`

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  height: 3rem;

  &:hover {
    background: ${darken(0.03, `${colors.main}`)};
  }
`
