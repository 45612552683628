import React, { useState, useEffect } from "react"

import { menu } from "../Header/content"
import Logo from "../../images/logo.png"
import { Link } from "gatsby"

import * as S from "./styles"
import { colors } from "../../styles/colors"

export default function MenuPhone() {
  const [menuItems, setMenuItems] = useState([])
  const [menuDown, setMenuDown] = useState(false)

  useEffect(() => {
    let itemsMenu = []

    menu.forEach(item => {
      if (item.subUrl.length === 0) itemsMenu.push(item)
      if (item.subUrl.length > 0) itemsMenu = itemsMenu.concat(item.subUrl)
    })

    setMenuItems(itemsMenu)
  }, [])

  return (
    <S.MenuWrapper>
      <S.LogoContainer onClick={() => setMenuDown(false)}>
        <Link to="/">
          <S.ImageLogo src={Logo} alt="Imagem Logo" />
        </Link>
      </S.LogoContainer>
      <S.ButtonBurger
        type="button"
        menuDown={menuDown}
        onClick={() => setMenuDown(!menuDown)}
      >
        <S.Dot />
        <S.Dot />
        <S.Dot />
      </S.ButtonBurger>
      <S.MenuList>
        {menuItems.map((itemMenu, idx) => (
          <Link
            to={itemMenu.url}
            key={idx}
            activeStyle={{
              color: colors.second,
              fontWeight: "bold",
            }}
          >
            <S.MenuItem>{itemMenu.label}</S.MenuItem>
          </Link>
        ))}
      </S.MenuList>
    </S.MenuWrapper>
  )
}
