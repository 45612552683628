import styled from "styled-components"
import { colors } from "../../styles/colors"

export const FooterWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${colors.main};
  padding: 0.5rem;
`

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  max-width: 58.75rem;
  height: 100%;
  margin: 0 auto;
`

export const FooterTitle = styled.h2`
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 400;
  color: ${colors.second};
  text-align: center;
  margin-top: 0.10rem;
  margin-bottom: 0.25rem;
`

export const SecondFooter = styled.p`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: bolder;
  color: ${colors.second};
  letter-spacing: 1px;
  text-align: center;
  margin-top: 0.5rem;
`

export const FooterLinksList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const FooterLinkItem = styled.a`
  justify-content: center;
  width: 10rem;
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50px;
  width: 8rem;
  height: 2.4rem;
  margin-right: 0.62rem;
`

export const SocialMediaText = styled.span`
  margin-left: 0.62rem;
  font-weight: 600;
  color: ${colors.secondFont};
`
