import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { DownArrow } from "@styled-icons/boxicons-solid/DownArrow"
import { menu } from "./content"
import MenuPhone from "../MenuPhone"

import * as S from "./styles"

export default function Header() {
  const [menuDown, setMenuDown] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [windowSize, setWindowSize] = useState(
    typeof window !== "undefined" && window.innerWidth
  )

  useEffect(() => {
    // const isClient = typeof window !== 'undefined'
    if (typeof window !== "undefined") {
      window.addEventListener("resize", function() {
        setWindowSize(window.innerWidth)
      })

      document.addEventListener("scroll", () => {
        const currentScrollPos = window.pageYOffset;
        setScroll(currentScrollPos >= 10);
      });

    }
  }, [])

  const handleMouseOver = menuOption => {
    if (menuOption.subUrl.length === 0) return
    setMenuDown(true)
  }

  const handleMouseLeave = menuOption => {
    if (menuOption.subUrl.length === 0) return
    setMenuDown(false)
  }

  return (
    <>
      {windowSize <= 768 ? (
        <MenuPhone />
      ) : (
        <S.HeaderWrapper visible={scroll}>
          <S.HeaderContent visible={scroll}>
            <S.HeaderNavBar visible={scroll}>
              <S.HeaderList>
                {menu.map(menuItem => (
                  <S.HeaderItem
                    visible={scroll}
                    menuDown={menuDown}
                    key={menuItem.label}
                    onMouseOver={() => handleMouseOver(menuItem)}
                    onFocus={() => handleMouseOver(menuItem)}
                    onMouseLeave={() => handleMouseLeave(menuItem)}
                  >
                    <Link
                      to={menuItem.url !== "" ? `${menuItem.url}` : null}
                    >
                      <S.MenuOptionItem>{menuItem.label}</S.MenuOptionItem>
                      {menuItem.subUrl.length > 0 && <DownArrow size={9} />}
                    </Link>
                    {menuItem.subUrl.length > 0 && (
                      <S.HeaderList
                        menuDown={menuDown}
                        sub
                        onMouseLeave={() => handleMouseLeave(menuItem)}
                      >
                        {menuItem.subUrl.map(subMenu => (
                          <S.HeaderItem sub key={subMenu.label}>
                            <Link
                              to={`${subMenu.url}`}
                            >
                              <S.MenuOptionItem>
                                {subMenu.label}
                              </S.MenuOptionItem>
                            </Link>
                          </S.HeaderItem>
                        ))}
                      </S.HeaderList>
                    )}
                  </S.HeaderItem>
                ))}
              </S.HeaderList>
            </S.HeaderNavBar>
          </S.HeaderContent>
        </S.HeaderWrapper>
      )}
    </>
  )
}
