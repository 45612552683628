import { Whatsapp } from "@styled-icons/remix-fill/Whatsapp"
import { FacebookWithCircle as Facebook } from "@styled-icons/entypo-social/FacebookWithCircle"
import { Email } from "@styled-icons/material-rounded/Email"
import { InstagramWithCircle } from "@styled-icons/entypo-social/InstagramWithCircle"

const Icons = {
  Whatsapp,
  Facebook,
  Email,
  Instagram: InstagramWithCircle,
}

export default Icons
