import styled from "styled-components"
import media from "styled-media-query"

export const LayoutWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LayoutMain = styled.main`
  width: 100%;

  ${media.lessThan("medium")`
    margin-top: 75px;
  `}
`
