import { colors } from "../../styles/colors"

const replaceSpacesToLink = message => {
  return message.replace(/\s+/g, "%20")
}

const createLinkWhatsapp = (numberPhone, message) => {
  const formatedString = replaceSpacesToLink(message)
  const url = `https://wa.me/${numberPhone}?text=${formatedString}`

  return url
}

const createLinkEmail = (email, message) => {
  const formatedString = replaceSpacesToLink(message)
  return `mailto:${email}?Subject=${formatedString}`
}

export const links = [
  {
    label: "Whatsapp",
    url: createLinkWhatsapp(
      "5518996174447",
      "Olá, gostaria de fazer um orçamento!"
    ),
    color: "#00E676",
  },
  {
    label: "Email",
    url: createLinkEmail(
      "sg.contabilidade.assessoria@gmail.com",
      "Olá, gostaria de fazer um orçamento!"
    ),
    color: colors.main,
  },
  {
    label: "Facebook",
    url: "https://www.facebook.com/sgcontabilassessoria/",
    color: "#0582ED",
  },
  {
    label: "Instagram",
    url: "https://www.instagram.com/sgassessoriacontabil/",
    color: "#833AB4",
  },
]
